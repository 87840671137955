:root {
	--color-present: var(--yellow);
	--color-correct: var(--green);
	--color-absent: var(--color-tone-2);
	--tile-text-color: var(--color-tone-7);
	--key-text-color: var(--color-tone-1);
	--key-evaluated-text-color: var(--color-tone-7);
	--key-bg: var(--color-tone-4);
	--key-bg-present: var(--color-present);
	--key-bg-correct: var(--color-correct);
	--key-bg-absent: var(--color-absent);
	--modal-content-bg: var(--color-tone-7);
	
  --color-tone-1: #1a1a1b;
  --color-tone-2: #787c7e;
  --color-tone-3: #878a8c;
  --color-tone-4: #d3d6da;
  --color-tone-5: #edeff1;
  --color-tone-6: #f6f7f8;
  --color-tone-7: #ffffff;
  --opacity-50: rgba(255, 255, 255, 0.5);	
  
	--green: #6aaa64;
  --darkendGreen: #538d4e;
  --yellow: #c9b458;
  --darkendYellow: #b59f3b;
  --lightGray: #d8d8d8;
  --gray: #86888a;
  --darkGray: #939598;
  --white: #fff;
  --black: #212121;
  --orange: #f5793a;
  --blue: #85c0f9;
  font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  --header-height: 50px;
  --keyboard-height: 200px;
  --game-max-width: 500px;  
}

.toaster {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
  width: fit-content;
}
#game-toaster {
  z-index: 1000;
}
#system-toaster {
  z-index: 4000;
}

@media (max-width: 360px) {
  header .title {
    font-size: 22px;
    letter-spacing: 0.1rem;
  }
}

#board-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}
      
.GameArea {
	background-color: #aa2c34;
}

.LetterBox {
	background-color: #aa2c99;
}

button.icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 4px;
}

#debug-tools {
  position: absolute;
  bottom: 0;
}

#board {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 5px;
    padding: 10px;
    box-sizing: border-box;
    width: 350px;
    height: 420px;
}

#game {
    width: 100%;
    max-width: var(--game-max-width);
    margin: 0 auto;
    height:calc(100% - var(--header-height));
    display: flex;
    flex-direction: column;
}

header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0 16px;
    height: var(--header-height);
    color: var(--color-tone-1);
    border-bottom: 1px solid var(--color-tone-4);
    padding: 0px 16px;
}

header .title {
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
}

.tile {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bold;
    vertical-align: middle;
    box-sizing: border-box;
    color: var(--tile-text-color);
    text-transform: uppercase;
    user-select: none;
    border: 2px solid      
}

.tile[data-state='empty'] {
  border: 2px solid var(--color-tone-4);
}
.tile[data-state='tbd'] {
  background-color: var(--color-tone-7);
  border: 2px solid var(--color-tone-3);
  color: var(--color-tone-1);
}
.tile[data-state='correct'] {
  background-color: var(--color-correct);
}
.tile[data-state='present'] {
  background-color: var(--color-present);
}
.tile[data-state='absent'] {
  background-color: var(--color-absent);
}

:host {
  height: var(--keyboard-height);
}
#keyboard {
  margin: 0 8px;
  user-select: none;
}

.gamerow {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
}

.keyboardrow {
  display: flex;
  width: 100%;
  margin: 0 auto 8px;
  /* https://stackoverflow.com/questions/46167604/ios-html-disable-double-tap-to-zoom */
  touch-action: manipulation;
}

button {
  font-family: inherit;
  font-weight: bold;
  border: 0;
  padding: 0;
  margin: 0 6px 0 0;
  height: 58px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  background-color: var(--key-bg);
  color: var(--key-text-color);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  -webkit-tap-highlight-color: rgba(0,0,0,0.3);
}

button:focus {
  outline: none;
}

button.fade {
  transition: background-color 0.1s ease, color 0.1s ease;
}

button:last-of-type {
  margin: 0;
}

.half {
  flex: 0.5;
}

.one {
  flex: 1;
}

.one-and-a-half {
  flex: 1.5;
  font-size: 12px;
}

.two {
  flex: 2;
}

button[data-state='correct'] {
  background-color: var(--key-bg-correct);
  color: var(--key-evaluated-text-color);
}

button[data-state='present'] {
  background-color: var(--key-bg-present);
  color: var(--key-evaluated-text-color);
}

button[data-state='absent'] {
  background-color: var(--key-bg-absent);
  color: var(--key-evaluated-text-color);
}

  